import React, { useContext, useState, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreement1A() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    const inputVal = e.target.value
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    })

    keyboard.current.setInput(inputVal)
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="dt-flex flex-column m-4">
        <h1 className="display-5 font-weight-bold">Appendix 1</h1>

        <h3 className="font-weight-bold ml-4">
          Acknowledgement of Substitution (pt 1)
        </h3>
        <hr />
        <p className="lead">
          <strong>Important Notes:</strong>
        </p>
        <p className="lead">
          <strong>1.</strong> It is the Contractor and Substitute's
          responsibility to submit this Acknowledgement of Substitution to
          ALPHAVIBE
          <br />
          <strong>2.</strong> The Substitute must complete this acknowledgement
          form to officially authorise the Substitution.
          <br />
          <strong>3.</strong> ALPHAVIBE shall not be obliged to accept any such
          Substitute without the Acknowledgment of Substitution and the
          Contractor shall be liable to ALPHAVIBE in the manner set out in
          Clause 7 of the Contractor Agreement.
        </p>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => {
              saveDraftUser(user)
              navigate("/contractor-agreement-1b")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-f")}
          />
        </div>
      </div>
    </Background>
  )
}
